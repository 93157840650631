// prettier-ignore
export default {
  "% Fallas": "Fallas",
  "% Mala Hierba": "% Mala Hierba",
  "% Maleza (Ciclo<60 dias)": "Maleza",
  "ACELGA": "ACELGA",
  "ACELGA VERDE": "ACELGA VERDE",
  "action": "acción",
  "ag_accesses_the_viewer": "k. Accede AG al visor?",
  "AGUACATE": "AGUACATE",
  "AJO": "AJO",
  "ALBARICOQUE": "ALBARICOQUE",
  "ALBARINO": "ALBARINO",
  "ALFALFA": "ALFALFA",
  "ALGODON": "ALGODON",
  "aluminum": "Aluminio",
  "aluminum_saturation": "Saturación de Aluminio",
  "annotation": "anotación",
  "aphid_controller": "pulgón - controlador",
  "aphid_infested": "pulgón - infestación",
  "aphid_infested_leafs": "pulgón - hojas infestadas",
  "aphid_mite_total_leafs": "pulgón y ácaro - total hojas",
  "APIO": "APIO",
  "apparent_density": "Densidad aparente",
  "ARANDANOS": "ARANDANOS",
  "ARBOSANA": "ARBOSANA",
  "ARROZ": "ARROZ",
  "assesor_name": "Nombre Evaluador                ",
  "atr": "Atr",
  "AVENA": "AVENA",
  "BARBECHO": "BARBECHO",
  "BATAVIA": "BATAVIA",
  "beans_10_plants": "Plantas de 10 gramos",
  "billaea_healthy_pupe": "billaea - pupas sanas",
  "billaea_larva": "billaea - larvas",
  "billaea_parasitized_larva": "billaea - larvas parasitadas",
  "billaea_residual_pupe": "billaea - pupas residual",
  "bipolaris_sacchari_incidence": "bipolaris sacchari \"mancha del ojo\" - porcentaje de incidencia",
  "bipolaris_sacchari_severity": "bipolaris sacchari \"mancha del ojo\" - severidad",
  "bipolaris_sacchari_total_infected_leafs": "bipolaris sacchari \"mancha del ojo\" - total hojas infectadas",
  "BLANCO": "BLANCO",
  "blastobasis_healthy_chrysalis": "blastobasis - crisálidas sanas",
  "blastobasis_larva_number": "blastobasis - larvas",
  "blastobasis_residual_chrysalis": "blastobasis - crisálidas residual",
  "boron": "Boro",
  "bouquet10roots_2_8cm": "02.Moñitos 10 raíces (2-8cm)",
  "bouquet10roots_2cm": "01.Moñitos 10 raíces (0-2cm)",
  "bouquet10roots_8cm": "03.Moñitos 10 raíces (>8cm)",
  "brix": "Brix",
  "BROCOLI": "BROCOLI",
  "bud_blastobasis_drilled": "yemas diatraea blastobasis perforados",
  "bud_dehydrated": "yemas deshidratadas",
  "bud_diatrea_drilled": "yemas diatraea perforados",
  "bud_mechanical_damage": "yemas daño mecánico",
  "bud_sprout": "yemas - lalas",
  "bud_total": "yemas totales",
  "bud_turgid": "yemas turgentes",
  "CABERNET SAUVIGNON": "CABERNET SAUVIGNON",
  "CALABACIN": "CALABACIN",
  "calcium": "Calcio",
  "calcium_saturation": "Saturación de Calcio",
  "CAÑA DE AZUCAR": "CAÑA DE AZÚCAR",
  "CAQUI": "CAQUI",
  "casida_afa_perc": "Cásida - AFA(%)",
  "casida_n_of_adults_in_50_plants": "Cásida - Nº adultos en 50 plantas",
  "casida_n_of_eggs_in_50_plants": "Cásida - Nº huevos en 50 plantas",
  "casida_n_of_large_larvae_in_50_plants": "Cásida - Nº Larvas grandes 50 plantas",
  "casida_n_of_small_larvae_in_50_plants": "Cásida - Nº Larvas pequeñas 50 plantas",
  "CAUCHO": "CAUCHO",
  "CEBADA": "CEBADA",
  "CEBADA HIBRIDA": "CEBADA HIBRIDA",
  "CEBADA MALTERA": "CEBADA MALTERA",
  "CEBOLLA": "CEBOLLA",
  "cercospora_20_100_spots_leaf": "Cercospora -F- 20-100 manchas/hoja",
  "cercospora_50_afa": "Cercospora -L- 50% AFA",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Cercospora -K- 50% hojas ext con un 80-100% severidad",
  "cercospora_75_afa": "Cercospora -M- 75%AFA",
  "cercospora_90_100_afa": "Cercospora -N- 90-100%AFA",
  "cercospora_healthy_plant": "Cercospora -A- Planta sana",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Cercospora -D- Manchas aisladas en algunas hojas.(10-50%HS/100 hojas)",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Cercospora -C- Manchas aisladas en algunas hojas. (5-10%HS/100 hojas)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Cercospora -B- Manchas aisladas en algunas hojas. (<5%HS/100 hojas)",
  "cercospora_leaf_necrosis_ext": "Cercospora -H- Necrosis en hojas EXT.",
  "cercospora_leaves_ext_almost_total_necrosis": "Cercospora -J- Hojas EXT: necrosis casi total",
  "cercospora_leaves_with_major_100_sports": "Cercospora -G- Hojas con > 100 manchas",
  "cercospora_minus_20_spots_leaf": "Cercospora -E- <20 manchas/hoja",
  "cercospora_necrosis_on_most_leaves_int": "Cercospora -I- Necrosis en la mayoría de las hojas INT.",
  "cercospora_treatment": "Cercospora -o- Tratamiento",
  "CEREZA": "CEREZA",
  "CESPED": "CESPED",
  "CHARDONNAY": "CHARDONNAY",
  "chromium": "Cromo",
  "cic": "Cic",
  "CIRUELA": "CIRUELA",
  "clay": "Arcilla",
  "CLEMENTINA": "CLEMENTINA",
  "close": "cerrado",
  "COLZA": "COLZA",
  "Conteo": "Conteo",
  "copper": "Cobre",
  "cotesia_cocon": "cotesia - cocones",
  "cotesia_parasitized_larva": "cotesia - larvas parasitadas",
  "cotyledon": "cotiledones",
  "current_ndre_status": "a. Estado actual NDRE",
  "date_of_first_informative_email_sent": "j. Fecha envío primer mail informativo",
  "date_of_hemav_ndre_layer_screenshot": "h. Fecha envío captura Hemav capa NDRE",
  "defoliator_leaf_evaluated": "defoliador - hojas evaluadas",
  "defoliator_marasmia_sp_larva_alive": "defoliador - marasmia sp. - larvas vivas",
  "defoliator_marasmia_sp_larva_death": "defoliador - marasmia sp. - larvas muertas",
  "defoliator_marasmia_sp_larva_per_stem": "defoliador - marasmia sp. - larvas por tallo",
  "defoliator_mythimna_sp_larva_alive": "defoliador - mythimna sp. - larvas vivas",
  "defoliator_mythimna_sp_larva_death": "defoliador - mythimna sp. - larvas muertas",
  "defoliator_mythimna_sp_larva_per_stem": "defoliador - mythimna sp. - larvas por tallo",
  "defoliator_spodoptera_sp_larva_alive": "defoliador - spodoptera sp. - larvas vivas",
  "defoliator_spodoptera_sp_larva_death": "defoliador - spodoptera sp. - larvas muertas",
  "defoliator_spodoptera_sp_larva_per_stem": "defoliador - spodoptera sp. - larvas por tallo",
  "defoliator_stem_total": "defoliador - tallos totales",
  "depth": "Profundidad",
  "desconocido": "Desconocido",
  "DESCONOCIDO": "DESCONOCIDO",
  "diatraea_saccharalis_larva_big": "diatraea sachharalis - larvas grandes",
  "diatraea_saccharalis_larva_healthy_chrysalis": "diatraea sachharalis - crisálidas sanas",
  "diatraea_saccharalis_larva_medium": "diatraea sachharalis - larvas medianas",
  "diatraea_saccharalis_larva_residual_chrysalis": "diatraea sachharalis - crisálidas residual",
  "diatraea_saccharalis_larva_small": "diatraea sachharalis - larvas pequeñas",
  "diatraea_saccharalis_larva_total": "diatraea sachharalis - total larvas",
  "disease": "Enfermedad",
  "Disease": "Enfermedad",
  "disease_level": "08.Nivel de enfermedad",
  "distance_between_rows": "03.Dist entre surcos (cm)",
  "does_it_follow_the_recommendation": "f. ¿Sigue recomendación?",
  "Drip": "Goteo",
  "dry_weight": "Peso seco",
  "effective_cation_exchange_capacity": "Capacidad Efectiva de Intercambio Catiónico (CEIC)",
  "ESCAROLA": "ESCAROLA",
  "ESCAROLA LISA": "ESCAROLA LISA",
  "ESCAROLA RIZADA": "ESCAROLA RIZADA",
  "ESPARRAGO": "ESPARRAGO",
  "ESPINACA": "ESPINACA",
  "estrés hídrico": "Estrés hídrico",
  "Estrés hídrico (LWS)": "Estrés hídrico (LWS)",
  "evaluator": "10.Nombre evaluador",
  "Ex": "Ej.",
  "exchangeable_acidity": "Acidez Intercambiable",
  "fallas": "Fallas",
  "fire": "Incendio",
  "flowering": "Floración",
  "forecast_yield_sampling_1": "PREVISIÓN DE RENDIMIENTO 1",
  "forecast_yield_sampling_2": "PREVISIÓN DE RENDIMIENTO 2",
  "forecast_yield_sampling_3": "PREVISIÓN DE RENDIMIENTO 3",
  "forecast_yield_sampling_4": "PREVISIÓN DE RENDIMIENTO 4",
  "fresh_weight": "Peso fresco",
  "FRIJOL": "FRIJOL",
  "fruits_count": "Número de frutos",
  "FRUTAL": "FRUTAL",
  "Generic": "Genérica",
  "GEWURZTRAMINNER": "GEWURZTRAMINNER",
  "GIRASOL": "GIRASOL",
  "gm_avg_ndvi": "Vigor vegetativo (NDVI-Drone)",
  "gm_luvi_ndvi_mean": "Vigor Vegetativo (NDVI-LUVI)",
  "gm_ndmi_mean": "Humedad Vegetativa (NDMI) ",
  "gm_ndre_mean": "Nitrógeno-Clorofila (NDRE)",
  "gm_ndvi_gaps": "Fallas",
  "gm_ndvi_weeds": "Maleza",
  "gm_perc_overseeding": "% Faltas",
  "gm_perc_weeds": "% Mala Hierba",
  "GODELLO": "GODELLO",
  "GOLDEN DELICIOUS": "GOLDEN DELICIOUS",
  "GOLDEN REINDERS": "GOLDEN REINDERS",
  "Grams": "Gramos",
  "GRANADA": "GRANADA",
  "green_dots": "puntos verdes",
  "GRENACHE BLANC": "GRENACHE BLANC",
  "GUISANTE": "GUISANTE",
  "HABA": "HABA",
  "hanger_installation": "Instalación de percha",
  "height": "Altura",
  "HIBRIDO": "HIBRIDO",
  "high irrigation": "Estrés hídrico alto",
  "high_n_from_irrigation_water": "d. Alto N por agua de riego",
  "high_n_from_nitrogen_input": "e. Alto N por aportación N",
  "high_n_from_previous_years": "c. Alto N por años anteriores",
  "higher level": "Nivel Más Alto",
  "HIGO": "HIGO",
  "Humedad Vegetativa (NDMI) ": "Humedad Vegetativa (NDMI) ",
  "humidity": "Humedad",
  "humidity_100_beans": "Humedad de 100 gramos",
  "Imperial": "Imperial",
  "inspection": "inspección",
  "internodes_blastobasis_damage_longitude": "entrenudos blastobasis longitud daño",
  "internodes_blastobasis_drilled": "entrenudos blastobasis perforados",
  "internodes_diatraea_damage_longitude": "entrenudos diatrea longitud daño",
  "internodes_diatraea_drilled": "entrenudos diatrea perforados",
  "internodes_evaluated": "entrenudos total",
  "internodes_healthy": "entrenudos sanos",
  "internodes_mechanical_damage": "entrenudos daño mecánico",
  "internodes_physiological_damage": "entrenudos daño fisiológico",
  "iron": "Hierro",
  "irrigation": "IRRIGACIÓN",
  "Irrigation": "Riego",
  "irrigation_dosage": "Dosis Riego",
  "irrigation_uniformity": "Uniformidad de Riego",
  "KUMQUATS": "KUMQUATS",
  "language": "Lenguaje del sistema",
  "larva_number_big": "estadio larval - larva grande",
  "larva_number_medium": "estadio larval - larva mediana",
  "larva_number_small": "estadio larval - larva pequeña",
  "last_fertilizer_app_date": "b. Fecha última aplicación abono",
  "last_irrigation": "Último Riego",
  "lead": "Plomo",
  "Leaf": "Hoja",
  "leaf_moisture_status": "Estado Humedad Hoja",
  "LECHUGA": "LECHUGA",
  "LECHUGA ICEBERG": "LECHUGA ICEBERG",
  "leptosphaeria_sacchari_incidence": "leptosphaeria sacchari \"mancha anular\" - porcentaje de incidencia",
  "leptosphaeria_sacchari_severity": "leptosphaeria sacchari \"mancha anular\" - severidad",
  "leptosphaeria_sacchari_total_infected_leafs": "leptosphaeria sacchari \"mancha anular\" - total hojas infectadas",
  "LIMON": "LIMON",
  "linear_meters": "02.Metros lineales (m)",
  "loam": "Franco",
  "loss_of_vigor": "Pérdida de vigor",
  "low irrigation": "Estrés hídrico bajo",
  "lower level": "Nivel Más Bajo",
  "lowered_unload": "03.Se baja en descarga",
  "MACABEU": "MACABEU",
  "MACABEU VIURA": "MACABEU VIURA",
  "magnesium": "Magnesio",
  "magnesium_saturation": "Saturación de Magnesio",
  "MAIZ": "MAIZ",
  "maleza (ciclo<60 dias)": "Maleza",
  "MALVASIA AROMATICA": "MALVASIA AROMATICA",
  "MALVASIA DE SITGES": "MALVASIA DE SITGES",
  "MALVASIA GROSSA": "MALVASIA GROSSA",
  "MANDARINA": "MANDARINA",
  "manganese": "Manganeso",
  "MANZANA": "MANZANA",
  "mean level": "Nivel Medio",
  "medium irrigation": "Estrés hídrico medio",
  "medium_moisture_saturation": "Saturación Media de Humedad",
  "MELOCOTON": "MELOCOTON",
  "MELON": "MELON",
  "mercury": "Mercurio",
  "MERLOT": "MERLOT",
  "Metric": "Métrico",
  "microorganisms_larva": "larva microorganismos",
  "mite_grade": "ácaro - grado",
  "mite_infested": "ácaro - infestación",
  "mite_infested_leafs": "ácaro - hojas infestadas",
  "moisture_status": "07.Estado Humedad",
  "molybdenum": "Molybdeno",
  "MONTONEC": "MONTONEC",
  "MONTONEGA": "MONTONEGA",
  "MOSCATELL DE ALEXANDRIA": "MOSCATELL DE ALEXANDRIA",
  "MOSCATELL ROMA": "MOSCATELL ROMA",
  "NARANJA": "NARANJA",
  "NDRE": "NDRE",
  "NDVI": "NDVI",
  "NECATRINA": "NECATRINA",
  "NECTARINA": "NECTARINA",
  "nickel": "Níquel",
  "Nitrogen": "Nitrógeno",
  "Nitrógeno Foliar": "Nitrógeno foliar",
  "nitrógeno-clorofila (ndre)": "Nitrógeno-Clorofila (NDRE)",
  "Nitrógeno-Clorofila (NDRE)": "Nitrógeno-Clorofila (NDRE)",
  "No crop or too dry": "Sin cultivo o muy seco",
  "NO CULTIVO": "NO CULTIVO",
  "No hay datos": "No hay datos",
  "NOGAL": "NOGAL",
  "notification_to_the_farmer_high_n_index": "g. Notificación al agricultor Alto índice N",
  "Observations": "Observaciones",
  "OLIVO": "OLIVO",
  "opogona_larva_number": "opogona - larvas",
  "organic_material": "Materia orgánica",
  "organic_oxidizable_carbon": "Carbono Orgánico Oxidable",
  "PANSA BLANCA": "PANSA BLANCA",
  "PANSAL": "PANSAL",
  "PARAGUAYO": "PARAGUAYO",
  "PATATA": "PATATA",
  "pattern": "Patrón",
  "PERA": "PERA",
  "percent_disease": "Porcentaje de enfermedad",
  "perkinsiella_345_instar_nymph": "perkinsiella - ninfas 3-4-5 instar",
  "perkinsiella_adult": "perkinsiella - adultos",
  "perkinsiella_controller_coccinelidos": "perkinsiella - controladores - coccinelidos",
  "perkinsiella_controller_crysopas": "perkinsiella - controladores - crysopas",
  "perkinsiella_controller_spiders": "perkinsiella - controladores - arañas",
  "perkinsiella_controller_tytthus_parviceps": "perkinsiella - controladores - tytthus parviceps",
  "perkinsiella_controller_zelus": "perkinsiella - controladores - zelus",
  "perkinsiella_death_adult": "perkinsiella - adultos muertos",
  "perkinsiella_death_nymph": "perkinsiella - ninfas muertas",
  "petiole_sampling": "i. Toma muestras de peciolo",
  "ph": "PH",
  "pheromone_trap_diatraea_adults_number": "trampa feromonas - adultos diatraea",
  "pheromone_trap_installation_date": "trampa feromonas - fecha instalación",
  "pheromone_trap_number": "trampa feromonas - trampas",
  "pheromone_trap_other_butterfly": "trampa feromonas - otras mariposas",
  "pheromone_trap_pheromone_change": "trampa feromonas - cambio de feromona",
  "phosphorus": "Fósforo",
  "PIMIENTO": "PIMIENTO",
  "PINO": "PINO",
  "PINOT NOIR": "PINOT NOIR",
  "PISTACHO": "PISTACHO",
  "Pivot": "Pivote",
  "Plague": "Plaga",
  "plague_level": "09.Nivel de plaga",
  "Plant": "Planta",
  "Plant Density": "Densidad de planta",
  "plantation_date": "Fecha de plantación",
  "plants_per_surface_unit": "Plantas por unidad de superficie",
  "poda": "Poda",
  "pol": "Pol",
  "POMELO": "POMELO",
  "POMELOS": "POMELOS",
  "potassium": "Potasio",
  "potassium_saturation": "Saturación de Potasio",
  "powdery_mildew_afa_perc": "Oídio - AFA(%)",
  "powdery_mildew_perc_of_affected_plants": "Oídio - % de plantas afectadas  (INCIDENCIA)",
  "powdery_mildew_treatment": "Oídio - Tratamiento",
  "Production": "Producción",
  "pseudomonas_rubrilineans_stem_number": "raya roja - total de tallos",
  "pseudomonas_rubrilineans_stem_number_affected": "raya roja - tallos afectados",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "raya roja - tallos con síntomas",
  "puccinia_melanocephala_accumulated_scale": "roya - escala acumulado",
  "puccinia_melanocephala_infestation": "roya - infestación",
  "puccinia_melanocephala_infested_leafs": "roya - hojas infestadas",
  "puccinia_melanocephala_points": "roya - puntos",
  "puccinia_melanocephala_total_leafs": "roya - hojas totales",
  "puccinia_sp_incidence": "puccinia sp \"roya\" - incidencia",
  "puccinia_sp_severity": "puccinia sp \"roya\" - severidad",
  "puccinia_sp_total_infected_leafs": "puccinia sp \"roya\" - total hojas infectadas",
  "PUERRO": "PUERRO",
  "Quality": "Calidad",
  "QUINOA": "QUINOA",
  "RAIGRAS": "RAIGRAS",
  "real_leaves": "hojas verdaderas",
  "reform": "Reforma",
  "REMOLACHA": "REMOLACHA",
  "REMOLACHA AZUCARERA": "REMOLACHA AZUCARERA",
  "resowing_surface": "Superficie de Resiembra",
  "result": "resultado",
  "riego": "Riego",
  "rodents_infestation_percent": "Roedores - Infestación",
  "rot10roots_complete": "03.Pudrición 10 raíces- Entera",
  "rot10roots_nothing": "01.Pudrición 10 raíces - Nada",
  "rot10roots_partial": "02.Pudrición 10 raíces - Tocada",
  "rust_10_25_afa": "Roya -10-25 % AFA",
  "rust_100_afa": "Roya -N- 100 % AFA. Follaje original completamente destruido",
  "rust_25_35_afa": "Roya -J- 25-35 % AFA. 1/4 -1/3 del follaje está muy afectado",
  "rust_3_10_afa": "Roya -I- 3-10 % AFA",
  "rust_35_50_afa": "Roya -K- 35-50 % AFA.  1/3 - 1/2 del follaje está muy afectado",
  "rust_50_75_afa": "Roya -L- 50-75 % AFA. 2/2 follaje está muy afectado",
  "rust_75_90_afa": "Roya -M- 75-90 % AFA. Todo el follaje está muy afectado",
  "rust_healthy_plant": "Roya -A- Planta sana",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "Roya -B- Manchas aisladas en algunas hojas. 1-9 (%HS)",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "Roya -C- Manchas aisladas en algunas hojas. 10-19 (%HS)",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "Roya -D- Manchas aisladas en algunas hojas. 20-29 (%HS)",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "Roya -E- Manchas aisladas en algunas hojas. 30-50 (%HS)",
  "rust_regrowth_restart": "Roya -O- Inicio de rebrotes. Añadir 0,5 puntos por cada semana hasta la cosecha",
  "rust_spots_leaves_100_300": "Roya -H- Hojas con 100-300 manchas",
  "rust_spots_leaves_20_100": "Roya -G- 20-100 manchas por hoja en la mayoría las hojas",
  "rust_spots_leaves_50": "Roya -F- >50% HS y <20 manchas por hoja en la mayoría de las hojas",
  "rust_treatment": "Roya -p- Tratamiento",
  "sac": "Sac",
  "Safety": "Seguridad",
  "sample_color": "05.Color muestra",
  "sand": "Arena",
  "SAUVIGNON BLANC": "SAUVIGNON BLANC",
  "severity": "Severidad",
  "soca_width": "04.Nº raices",
  "sodium": "Sodio",
  "sodium_saturation": "Saturación de Sodio",
  "Soil": "Suelo",
  "soil_moisture_status": "Estado Humedad Suelo",
  "SOJA": "SOJA",
  "sowing_failure": "Fallo en la siembra",
  "sprout_dead": "brotes muertos",
  "sprout_total": "total brotes",
  "starch": "Almidón",
  "stems_evaluated": "tallos evaluados",
  "sulfur": "Azufre",
  "Superficial": "Superficial",
  "SYRAH": "SYRAH",
  "TABACO": "TABACO",
  "TE": "TE",
  "TEA": "TEA",
  "TEMPRANILLO": "TEMPRANILLO",
  "TOMATE": "TOMATE",
  "tombing": "Plantas caídas",
  "total_arsenic": "Arsénico Total",
  "total_cadmium": "Cadmio Total",
  "TOURIGA NACIONAL": "TOURIGA NACIONAL",
  "TRIGO": "TRIGO",
  "TRIGO BLANDO": "TRIGO BLANDO",
  "TRIGO DURO": "TRIGO DURO",
  "TRITICALE": "TRITICALE",
  "type_disease": "Tipo de enfermedad",
  "uneven_budding": "Brotación desigual",
  "ustilago_scitaminea_infection": "ustilago scitaminea \"carbón\" - infección",
  "ustilago_scitaminea_stem_affected": "ustilago scitaminea \"carbón\" - total tallos enfermos",
  "ustilago_scitaminea_stem_symptoms": "ustilago scitaminea \"carbón\" - tallos con síntomas",
  "ustilago_scitaminea_stem_total": "ustilago scitaminea \"carbón\" - tallos totales",
  "ustilago_scitaminea_stem_whip": "ustilago scitaminea \"carbón\" - tallos látigo",
  "UVA": "UVA",
  "UVA DE MESA": "UVA DE MESA",
  "Variabilidad": "Variabilidad",
  "Variabilidad (NDVI-LUVI)": "Variabilidad (NDVI-LUVI)",
  "VERDEJO": "VERDEJO",
  "very high irrigation": "Estrés hídrico muy alto",
  "very low irrigation": "Estrés hídrico muy bajo",
  "VIGNE": "VIGNE",
  "Vigor Vegetativo (NDVI-Drone)": "Vigor vegetativo (NDVI-Drone)",
  "Vigor Vegetativo (NDVI-LUVI)": "Vigor vegetativo (NDVI-LUVI)",
  "vigor vegetativo (ndvi) - vigor alto": "Vigor vegetativo (NDVI) - Vigor alto",
  "VIÑA": "VIÑA",
  "VIOGNIER": "VIOGNIER",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "trampa hembra virgen - total adultos diatraea",
  "virgin_female_georeferenced_trap_female_change": "trampa hembra virgen - cambio de hembra",
  "virgin_female_georeferenced_trap_other_butterfly": "trampa hembra virgen - otras mariposas",
  "virgin_female_georeferenced_trap_point": "trampa hembra virgen - punto de trampa",
  "virgin_female_trap_diatraea_adult_number": "trampa hembra virgen - adultos diatraea",
  "virgin_female_trap_female_change": "trampa hembra virgen - cambio de hembra",
  "virgin_female_trap_installation_date": "trampa hembra virgen - fecha instalación",
  "virgin_female_trap_number": "trampa hembra virgen - trampas",
  "virgin_female_trap_other_butterfly": "trampa hembra virgen - otras mariposas",
  "VIURA": "VIURA",
  "weed_unload": "01.Hierbas (descarga)",
  "Weeds": "Malas Hierbas",
  "weight_100_beans": "Plantas de 100 gramos",
  "weight_kg": "06.Peso muestra (Kg)",
  "whey": "Suero",
  "without_footwear": "01.Sin Calzado",
  "without_gloves": "04.Sin Guantes",
  "without_vest": "02.Sin Chaleco",
  "XARELLO": "XARELLO",
  "yellowness_mild": "Amarillez - Ataque leve (plantas sueltas)",
  "yellowness_moderate": "Amarillez - Ataque moderado (rodales)",
  "yellowness_strong": "Amarilla - Ataque fuerte (parcela completamente afectada)",
  "yellowness_without_symptoms": "Amarillez - Sin síntomas",
  "zinc": "Zinc"
}
